import i18n from '@/plugins/i18n'

export const reports=[
    {
        value: '1',
        text: i18n.t('registration_numbers_by_faculty_report'),
        permission:'studentreport_registrationnumbersbyfaculty'
    },
    {
        value: '2',
        text: i18n.t('number_of_students_by_status_report'),
        permission:'studentreport_numberofstudentsbystatus'
    },
    {
        value: '3',
        text: i18n.t('graduation_data_of_registrar'),
        permission:'studentreport_graduationdatareport'
    },
    {
        value: '4',
        text: i18n.t('demographics_of_current_students'),
        permission: 'studentreport_demographicscurrentstudentsreport'
    },
    {
        value: '5',
        text: i18n.t('number_of_current_students_between_selected_dates'),
        permission: 'studentreport_numberofcurrentstudentsreport'
    },
    {
        value: '6',
        text: i18n.t('list_of_Students_in_the_selected_academic_year'),
        permission: 'studentreport_listofstudentsreport'
    },
    {
        value: '7',
        text: i18n.t('undergraduate_students_who_take_less_than_full_load_of_courses'),
        permission: 'studentreport_listofstudentsreport'
    },
    {
        value: '8',
        text: i18n.t('graduate_students_who_take_less_than_full_load_of_courses'),
        permission: 'studentreport_undergraduatestudentscourselistreport'
    },
    {
        value: '9',
        text: i18n.t('students_who_have_not_enrolled_in_any_classes_in_the_current_semester'),
        permission: 'studentreport_studentsnotenrolledcourselistreport'
    },
    {
        value: '10',
        text: i18n.t('number_of_courses_that_each_student_is_enrolled_by_year_semester'),
        permission:'studentreport_studentsyearlycourselistreport'
    },
    {
        value: '11',
        text: i18n.t('number_of_students_that_must_take_the_core_courses'),
        permission: 'studentreport_studentsmustcourselistreport'
    },
    {
        value: '12',
        text: i18n.t('students_who_are_likely_to_graduate_in_the_next_3_semesters'),
        permission: 'studentreport_studentsmustcourselistreport'

    },
    {
        value: '13',
        text: i18n.t('students_who_are_likely_to_graduate_in_the_next_2_semesters'),
        permission: 'studentreport_studentsgraduateinthetwosemestersreport'
    },
    {
        value: '14',
        text: i18n.t('students_who_are_likely_to_graduate_next_semester'),
        permission: 'studentreport_studentsgraduateinthenextsemesterreport'
    },
    {
        value: '15',
        text: i18n.t('students_who_are_likely_to_graduate_in_the_current_semester'),
        permission: 'studentreport_studentsgraduateinthecurrentsemesterreport'
    },
    {
        value: '16', text: i18n.t('sap_report'),
        permission: 'studentsemester_sapreport'
    },
    {
        value: '17',
        text: i18n.t('headcount_by_major_report'),
        permission: 'studentreport_headcountbymajorreport'
    },
    {
        value: '18',
        text: i18n.t('degree_seeking_enrollment_degrees_report'),
        permission: 'studentreport_degreeseekingenrollmentanddegreesreport'
    },
    {
        value: '19',
        text: i18n.t('credit_hours_generated_report'),
        permission: 'studentreport_credithoursgeneratedreport'
    },
    {
        value: '20',
        text: i18n.t('diversity_report'),
        permission: 'studentreport_diversityreport'
    },
    {
        value: '21',
        text: i18n.t('enrollment_report'),
        permission: 'studentreport_enrollmentreport'
    },
    {
        value: '22',
        text: i18n.t('admissionReport'),
        permission: 'studentreport_admissionreport'
    },
    {
        value: '23',
        text: i18n.t('registrar_report'),
        permission: 'studentreport_registrarreport'
    },
    {
        value: '24',
        text: i18n.t('course_approval_check_report'),
        permission: 'studentreport_courseapprovalcheckreport'
    },
    {
        value: '25',
        text: i18n.t('course_registration'),
        permission:'studentcourse_studentcourseincludedreport'
    },
    {
        value: '26',
        text: "Registration Report",
        permission: 'studentreport_registrationreport'
    },
    {
        value: '27',
        text: "Attendance Entry Tracking Report",
        permission: 'dailyattendance_entrytrackingreport'
    }
]
